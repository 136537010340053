<template>
  <div class="container">
    <AccountCreatedLoader :accountCreated="accountCreated" />
    <div class="ui active inverted dimmer page-loader" v-if="loading">
      <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
    </div>
    <router-view v-else :locked="locked" :products="products" @refetch-products="fetchProducts" />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { getLockedProducts } from '@/utils/utils'
import { authGetters } from '@/store/modules/auth/routines'
import { getRecommendationsList } from '@product/api/opportunity'
import { amplitudeEvents } from '@/utils/constants'
import convertToCamelCase from '@product/features/products/mixins/convertToCamelCase'
import AccountCreatedLoader from '@/components/AccountCreatedLoader'
import { currentFormNameGetters } from '@/store/modules/currentFormDetails/routines'

export default {
  components: { Spinner, AccountCreatedLoader },
  mixins: [convertToCamelCase],
  async mounted() {
    this.accountCreated = !!localStorage.getItem('accountCreated')

    const lockedProducts = await getLockedProducts(this.user?.goalsCompleted)
    this.lockedProducts = lockedProducts

    if (!this.locked) {
      this.fetchProducts()
    } else {
      this.accountCreated = false
      localStorage.removeItem('accountCreated')
      this.loading = false
    }
  },
  data() {
    return {
      loading: true,
      lockedProducts: {
        loansLocked: false,
        equityLocked: true,
        grantsLocked: false,
        mortgageLocked: true
      },
      opportunities: [],
      opportunityList: [],
      enabledWhitelabelList: process.env.VUE_APP_TENANT === 'uk' || process.env.VUE_APP_TENANT === 'canada',
      enabledCountries: ['United Kingdom', 'Ireland'], // defaultCountry for ireland and all the other uk whitelabels is still 'United Kingdom'
      companyData: {},
      selectedLoan: null,
      accountCreated: false
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      user: authGetters.USER,
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME
    }),
    getProductType() {
      switch (this.$route.name) {
        case 'loan-matches':
          return 'loans'
        case 'equity-matches':
          return 'equity'
        case 'grant-matches':
          return 'grants'
        default:
          return true
      }
    },
    locked() {
      switch (this.$route.name) {
        case 'loan-matches':
          return this.lockedProducts.loansLocked
        case 'equity-matches':
          return this.lockedProducts.equityLocked
        case 'grant-matches':
          return this.lockedProducts.grantsLocked
        default:
          return true
      }
    },
    products() {
      switch (this.$route.name) {
        case 'loan-matches':
          return this.opportunities.filter(product => product.type === 'loan' || product.type === 'liveoffer')
        case 'equity-matches':
          return this.opportunities.filter(product => product.type === 'equity')
        case 'grant-matches':
          return this.opportunities.filter(product => product.type === 'grant')
        default:
          return []
      }
    }
  },
  methods: {
    fetchProducts() {
      switch (this.$route.name) {
        case 'loan-matches':
          return this.loadLoanOpportunityList()
        case 'equity-matches':
          return this.loadEquityPage()
        case 'grant-matches':
          return this.loadGrantOpportunityList()
        default:
          return []
      }
    },
    mapOpportunities(opportunities) {
      this.opportunities = opportunities.map(product => {
        return {
          ...product,
          type: product.type.toLowerCase(),
          status: product.status ? product.status.toLowerCase() : product.status,
          isApplication: Boolean(product.opportunityApplicationId),
          route: this.viewProductRoute(product),
          logo: product.logoUri
        }
      })
    },
    viewProductRoute(product) {
      return product.opportunityApplicationId
        ? { name: 'deal-details', params: { opportunityApplicationId: product.opportunityApplicationId } }
        : { name: 'funding-explore-deal', params: { opportunityId: product.opportunityId } }
    },
    trackProduct() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.Matches.MATCHES_PAGE_LOADS,
        eventProperties: {
          productType: this.getProductType,
          matches: this.products.length,
          locked: this.locked,
          matched: this.products.map(product => `${product.productName} - ${product.subcategory} - ${product.providerName}`),
          matchedIds: this.products.map(product => product.opportunityId)
        }
      })
    },
    loadLoanOpportunityList() {
      if (this.currentFormName) {
        getRecommendationsList(this.companyId, this.currentFormName)
          .then(({ data: { opportunities } }) => {
            this.opportunityList = opportunities
            this.mapOpportunities(this.opportunityList)
            this.trackProduct()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.loading = false
            this.accountCreated = false
            localStorage.removeItem('accountCreated')
          })
      }
      this.loading = false
    },
    loadGrantOpportunityList() {
      getRecommendationsList(this.companyId, 'findGrant')
        .then(({ data: { opportunities } }) => {
          this.opportunityList = opportunities
          this.mapOpportunities(this.opportunityList)
          this.trackProduct()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
          this.accountCreated = false
          localStorage.removeItem('accountCreated')
        })
    },
    loadEquityPage() {
      this.loading = false
      this.accountCreated = false
      localStorage.removeItem('accountCreated')
    }
  },
  watch: {
    $route() {
      this.loading = true
      if (!this.locked) {
        this.fetchProducts()
      } else {
        this.loading = false
      }
    },
    opportunityList(value) {
      this.opportunityList = value
    },
    user() {
      this.lockedProducts = getLockedProducts(this.user?.goalsCompleted)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container {
  max-width: 1100px;
  @media only screen and (max-width: $breakpoint-md-max) {
    padding: 0 30px;
  }
}

/deep/.layout-loading {
  left: 0;
  top: 56px;
  .loading-content {
    background-color: rgba(249, 250, 251, 1);
  }
}

/deep/.matches-header {
  position: relative;
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-wrap: wrap;
  }
}

/deep/.btn-white {
  font-size: 13px;
  position: absolute;
  right: 0;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid var(--color-secondary-50);
  border-radius: 8px;
  display: flex;
  background-color: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);

  @media only screen and (max-width: $breakpoint-sm-max) {
    right: 20px;
  }
  &:hover {
    background-color: var(--color-primary-400);
    color: $color-white;
  }
  .material-icons {
    margin: 0 5px;
    font-size: 15px;
  }
}

/deep/.grant-matches-header {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: $breakpoint-md-max) {
    flex-wrap: wrap;
  }
  .asterisk {
    @media only screen and (max-width: $breakpoint-sm-max) {
      font-size: 13px;
      line-height: 1.6;
    }
  }
}

/deep/.matches-title {
  @media only screen and (max-width: $breakpoint-sm-max) {
    font-size: 22px;
  }
}

/deep/.matches-subtitle {
  margin: 0 0 10px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    font-size: 13px;
    line-height: 1.6;
  }
}

/deep/.matches-wrapper {
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 12px;
  }

  h1 {
    font-size: 28px;
  }

  .product-unlocked-card {
    background-color: $color-white;
    text-align: center;
    padding: 72px 128px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--color-secondary-50);

    @media only screen and (max-width: $breakpoint-sm-max) {
      padding: 12px;
    }

    h2 {
      font-size: 21px;
    }

    p {
      font-size: 18px;
      color: var(--color-primary-400);
    }

    .overview-button {
      width: 398px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      margin: auto;

      @media only screen and (max-width: $breakpoint-sm-max) {
        width: 100%;
      }
    }
  }
}

.tab-hidden {
  display: none;
}

/deep/.filter-bar-container {
  //grants filterbar
  margin-top: 20px;
  border: 1px solid var(--color-secondary-50);
  border-radius: 8px;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: space-between;
  background-color: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);

  @media only screen and (max-width: $breakpoint-sm-max) {
    margin-bottom: 20px;
    align-items: center;
  }

  .sort-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: $breakpoint-sm-max) {
      flex-direction: column;
      align-items: flex-start;
    }

    .results {
      padding: 0 20px;
    }

    .sort-menu {
      display: flex;
      flex-direction: row;
    }

    .sort-btn {
      cursor: pointer;
      margin-left: 30px;
      border: 1px solid var(--color-secondary-50);
      border-radius: 8px;
      display: flex;

      @media only screen and (max-width: $breakpoint-sm-max) {
        margin: 0;
      }

      &:hover,
      &:active,
      &.active {
        background-color: var(--color-secondary-500);
        color: $color-white;
      }

      .sort-title {
        white-space: nowrap;
        margin: 5px;
        @media only screen and (max-width: $breakpoint-sm-max) {
          width: 80px;
        }
      }
      .divider {
        border-right: 1px solid var(--color-secondary-50);
        padding: 12px 0;
        background-color: transparent;
      }
    }

    .hidden {
      display: none;
    }
  }

  .material-icons {
    margin: 0 5px;
    vertical-align: middle;
    font-size: 20px;
    transition: all 0.4s ease;
  }
  .rotate {
    transform: rotate(180deg);
  }
}
/deep/.margin {
  margin: 20px 0;
}
@media only screen and (max-width: 770px) {
  .container {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: $breakpoint-lg-max) {
  .container {
    width: calc(92vw - #{$sidebar-width});
  }
}
@media only screen and (max-width: $breakpoint-md) {
  .container {
    width: 100%;
  }
}
</style>
