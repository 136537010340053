<template>
  <transition name="fade">
    <div v-if="accountCreated" class="container">
      <div class="loading-screen">
        <div class="wrapper">
          <div class="content">
            <em class="material-icons icon">account_circle </em>
            <p class="bold">Account created!</p>
            <div>Checking your matching results...</div>
          </div>
          <spinner :size="64" :loader="true" color />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'accountCreatedLoader',
  props: {
    accountCreated: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.container {
  position: relative;
  z-index: 1000;
  transition: opacity 1s;
  display: flex;

  .loading-screen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--color-primary-500);
    opacity: 0.98;
    display: flex;

    .wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .content {
        text-align: center;
        color: $color-white;
        margin-bottom: 30px;

        .icon {
          font-size: 45px;
        }
      }
    }
  }
}

 .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
